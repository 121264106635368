import "./App.css";
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

function ACSwitch() {
  return (
    <>
      <button onClick={handlerForOn}>ON</button>
      <button onClick={handlerForOff}>OFF</button>
    </>
  );
}

const handlerForOff = (e) => {
  e.preventDefault();
  axios
    .post("/ac-api/ac-controll/switch", {
      cmd: "ac_off",
    })
    .then(() => {
      console.log("switch off");
    });
};
const handlerForOn = (e) => {
  e.preventDefault();
  axios
    .post("/ac-api/ac-controll/switch", {
      cmd: "ac_on",
    })
    .then(() => {
      console.log("switch on");
    });
};
export default ACSwitch;
